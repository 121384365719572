import axios from 'axios';
import * as PropTypes from 'prop-types';
import queryString from 'query-string';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';

import * as NetworkStore from 'stores/Network';
import * as DashboardStore from 'stores/Dashboard';
import {clearTokenInfo} from 'utility';

@withRouter
@connect(
    state => ({
        isImpersonated: state.network.tokenInfo.isImpersonation,
        hasReportsAccess: state.user.settings.hasReportsAccess,
        psCommunityAccess: state.user.settings.psCommunityAccess,
    }),
    _.assign({}, NetworkStore.actionCreators, DashboardStore.actionCreators)
)
export default class Sso extends React.Component {
    static propTypes = {
        isImpersonated: PropTypes.bool,
        hasReportsAccess: PropTypes.bool,
        ...NetworkStore.ActionShape,
        ...ReactRouterPropTypes,
    };

    componentDidMount() {
        const {match: {params}, hasReportsAccess, psCommunityAccess} = this.props;
        const {host = window.location.host} = queryString.parse(location.search);

        if (host !== window.location.host) {
            return window.location.replace(`https://${host}/sso/${params.app}/${params.action}${location.search}`);
        }

        if (
            !hasReportsAccess && params.app === 'sisense' ||
            !psCommunityAccess && params.app === 'community'
        ) {
            return window.location.replace(window.location.origin);
        }

        this.loadPage();
    }

    loadPage() {
        const {match: {params}, isImpersonated, stopNotifications} = this.props;

        if (params.action === 'login') {
            if (params.app === 'sisense') {
                axios
                    .get(`/AuthenticationService/api/v1.0/sisense/login?is_impersonated=${!!isImpersonated}&host=${window.location.host}&userAgent=${window.navigator.userAgent}`)
                    .then(({data: {redirectUrl}}) => window.location.replace(redirectUrl))
                    .catch(error => window.parent.postMessage({
                        name: 'sso error',
                        value: error,
                    }, window.location.hostname));
            } else if (params.app === 'community') {
                const {redirectUrl} = queryString.parse(location.search);
                window.location.replace(redirectUrl);
            }
        } else if (params.action === 'logout') {
            if (params.app === 'sisense') {
                document.getElementsByTagName('iframe')[0].addEventListener('load', () => {
                    stopNotifications();
                    clearTokenInfo();
                    history.push('/');
                });
            } else if (params.app === 'community') {
                axios.post('/AuthenticationService/api/v1/community/logout')
                    .then(() => {
                        clearTokenInfo();
                        history.push('/');
                    })
                    .catch(() => {
                        clearTokenInfo();
                        history.push('/');
                    });
            }
        }
    }

    render() {
        const {match: {params}} = this.props;

        return (
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                {params.action === 'logout' && params.app === 'sisense' && <iframe
                    src={`${process.env.REACT_APP_SISENSE_URL}/api/auth/logout`}
                    style={{display: 'none', width: 0, height: 0, position: 'absolute'}} />}
            </div>
        );
    }
}
